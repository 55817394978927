<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
    label-width="130px"
    label-suffix="："
  >
    <el-form-item label="充值流水号" prop="serialNum">
      {{ detailData.serialNum }}
    </el-form-item>
    <el-form-item label="充值金额($)" prop="amount">
      {{ detailData.amount | numberFilter }}
    </el-form-item>
    <el-form-item label="公司名称" prop="companyName">
      {{ detailData.companyName }}
    </el-form-item>
    <!-- <el-form-item label="纳税人识别号" prop="taxId">
      {{ detailData.taxId }}
    </el-form-item>
    <el-form-item label="银行开户行" prop="bank">
      {{ detailData.bank }}
    </el-form-item>
    <el-form-item label="银行账号" prop="bankAccount">
      {{ detailData.bankAccount }}
    </el-form-item> -->
    <el-form-item label="打款凭证" prop="certificate">
      <div class="imgsArea">
        <el-image
          :preview-teleported="true"
          class="image"
          v-for="(url, index) in certificateList"
          :key="index"
          :src="url"
          :preview-src-list="certificateList"
        >
        </el-image>
        <span
          class="textBtn"
          @click="lokinfoPDf(ite)"
          v-for="(ite, ind) in certificatePDFList"
          :key="ind"
          >{{ ite }}</span
        >
      </div>
    </el-form-item>
    <el-form-item label="审核结果" prop="state">
      <el-radio-group
        v-model="formData.state"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="2">通过</el-radio>
        <el-radio :label="3">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="收款凭证" prop="fileName" v-if="formData.state == 2">
      <UploadFile
        :limSize="10"
        type="other"
        class="ml10"
        ref="uploadRef"
        @success="uploadSuccess"
        v-model="formData.accessFilePath"
        :acceptType="['png', 'jpg', 'jpeg', 'pdf']"
      ></UploadFile>
      <div class="remark-text mt10">
        文件大小不能超过10M，格式为png、jpeg、jpg、pdf。
      </div>
      <div class="ml10" v-show="formData.fileName">
        <span>{{ formData.fileName }}</span>
        <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
      </div>
    </el-form-item>
    <el-form-item
      label="审核意见"
      prop="remark"
      v-if="formData.state == 3"
      :rules="[
        {
          required: formData.state == 3 ? true : false,
          message: '请输入审核意见',
        },
      ]"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="formData.remark"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>
<script>
import UploadFile from "@/components/UploadFile1.vue";
export default {
  components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formData: { state: "", fileName: "" },
      rules: {
        state: [{ required: true, message: "请选择审核结果" }],
        fileName: [{ required: true, message: "请上传收款凭证" }],
      },
    };
  },
  computed: {
    certificateList() {
      if (!this.detailData.certificate) {
        return [];
      }
      let arrAy = this.detailData.certificate.split(",");
      return arrAy.filter((element) => !element.endsWith(".pdf"));
      // return this.detailData.certificate.split(",");
    },
    certificatePDFList() {
      if (!this.detailData.certificate) {
        return [];
      }
      let arrAy = this.detailData.certificate.split(",");
      return arrAy.filter((element) => element.endsWith(".pdf"));
      // return this.detailData.certificate.split(",");
    },
  },
  methods: {
    lokinfoPDf(url) {
      window.open(url, "_blank");
    },
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        let uploadValid = true;
        if (this.$refs.uploadRef) {
          uploadValid = this.$refs.uploadRef.getValidate();
        }
        if (valid && uploadValid) {
          const { accessFilePath, state, remark, fileName } = this.formData;
          const data = {
            id: this.detailData.id,
            state,
          };
          if (state == 2) {
            // 审核通过需要收款凭据
            data.receiptCertificate = accessFilePath; // 收款凭据
            data.fileName = fileName; // 收款凭据
          } else {
            // 审核不通过需要审核理由
            data.remark = remark;
          }
          this.$emit("submit", data);
        }
      });
    },
    uploadSuccess(file) {
      this.formData.accessFilePath = file.accessPath;
      this.formData.accessFileId = file.fileId;
      this.formData.fileName = file.fileName;
      this.$refs.formRef.clearValidate("fileName");
    },
    handleRemoveFile() {
      this.formData.accessFilePath = "";
      this.formData.accessFileId = "";
      this.formData.fileName = "";
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 60%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
  .imgsArea {
    display: flex;
    flex-wrap: wrap;

    .image {
      width: 100px;
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
}
</style>
